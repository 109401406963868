<template>
  <index-contact />
</template>

<script>
import IndexContact from '@/components/Contact/Index';

export default {
  name: "Contact",
  components: {
    IndexContact,
  }
}
</script>

<style scoped>

</style>